"use client";

import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { useRouter } from "next/navigation";
import { createClient } from "src/lib/supabase/client";
import SpinIcon from "../icon/SpinIcon";
import { handleNewSession } from "@/hooks/useUser";
import { useForm, UseFormReturn } from "react-hook-form";
import { z } from "zod";
import { loginFormSchema } from "@/app/studio/(public)/pod/login/login-form-schema";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../ui/form";
import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";

interface LoginFormProps {
  form?: UseFormReturn<z.infer<typeof loginFormSchema>>;
  redirectURL?: string;
  domain?: "studio" | "admin";
  onSubmitParent?: Function;
}

export default function LoginForm({ form: passedForm, onSubmitParent, redirectURL, domain = "admin" }: LoginFormProps) {
  const localForm = useForm<z.infer<typeof loginFormSchema>>({ resolver: zodResolver(loginFormSchema) });
  const form = passedForm || localForm;
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const supabase = createClient();

  const router = useRouter();

  async function _login(loginData: z.infer<typeof loginFormSchema>) {
    setLoading(true);
    setError(null);
    const { data, error } = await supabase.auth.signInWithPassword(loginData);
    console.log("data", data);
    if (!error && data.user) {
      const tenants = await handleNewSession(data.user);
      if (tenants) {
        if (onSubmitParent) onSubmitParent();
        if (redirectURL) router.push(redirectURL);
      }

      if (!tenants && domain == "admin") {
        router.push("/login/no-tenants");
      }
    } else {
      console.log("An error occured");
      setError(error ? error.message : "Something went wrong");
      setLoading(false);
    }
  }

  return (
    <div className="login-page">
      <div className="desktop-app-wrapper">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(_login)} className="flex flex-col">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>E-mail Address</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <Input type="password" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {error && (
              <div>
                <span className="text-destructive">Error: {error}</span>
              </div>
            )}

            <Button type="submit" variant={domain == "admin" ? "flagship" : "default"}>
              <SpinIcon loading={loading} className="me-2" />
              Log in
            </Button>
          </form>
        </Form>
      </div>
    </div>
  );
}
